
export function numberWithSpace(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
  }
  export default function getEnv(name) {
    return window?.configs?.[name] || process.env[name]
  }
export function formatAsCurrency(value, dec) {     
    dec = dec || 0;
    if (value === null) {
      return 0;
    }
    if (value === "undefined") {
      return 0;
    }   
    return value
      ? value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")       
      : 0;
  }


export function sumTotal(list,key) {
    // sum data in give key (property)
    return list
      ? list.reduce((a, b) => a + (b[key] || 0), 0)
      : 0;
  }
  export function debounce (fn, delay) {
    var timeoutID = null
    return function () {
      clearTimeout(timeoutID)
      var args = arguments
      var that = this
      timeoutID = setTimeout(function () {
        fn.apply(that, args)
      }, delay)
    }
  }
// export function datefr(date_o) {
//     let o_date 
//     if (!date_o) o_date = "";
//     else o_date = date_o;    
//     return (
//       o_date.substr(8, 2) +
//       "-" +
//       o_date.substr(5, 2) +
//       "-" +
//       o_date.substr(0, 4)
//     );
//   }
  export function datefr(date, time = false,m="long") {
    let t = "";
    let option = {
      year: "numeric",
      month: m,
      day: "numeric",
    };
    if (time)
      option = {
        year: "numeric",
        month: m,
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
    if (date) {
      var result = new Date(date);
      t = result.toLocaleDateString("fr-FR", option);
    }
    return t;
  }
  
  export const autorisations =[
   
    {
        "code": "01001",       
        "autorisation": "Consulter Tier",
        "module": "6",
        "__typename": "Autorisation"
    },

    {
        "code": "02001",
        "autorisation": "Editer Tier",
        "module": "6",
        "__typename": "Autorisation"
    },
    {
        "code": "03001",
        "autorisation": "Valider Tier",
        "module": "6",
        "__typename": "Autorisation"
    },
    {
        "code": "01002",
        "autorisation": "Consulter Produits (Achat)",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02002",
        "autorisation": "Editer Produits (Achat)",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03002",
        "autorisation": "Valider Produits (Achat)",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01003",
        "autorisation": "Consulter Produits (Vente)",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02003",
        "autorisation": "Editer Produits (Vente)",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03003",
        "autorisation": "Valider Produits (Vente)",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01004",
        "autorisation": "Consulter Produits (Prod)",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "02004",
        "autorisation": "Editer Produits (Prod)",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "03004",
        "autorisation": "Valider Produits (Prod)",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "01005",
        "autorisation": "Consulter Commande Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02005",
        "autorisation": "Editer Commande Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03005",
        "autorisation": "Valider Commande Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "04005",
        "autorisation": "Solder Commande Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },

    {
        "code": "05005",
        "autorisation": "Amender Commande Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01006",
        "autorisation": "Consulter Commande Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02006",
        "autorisation": "Editer Commande Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03006",
        "autorisation": "Valider Commande Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "04006",
        "autorisation": "Solder Commande Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "05006",
        "autorisation": "Amender Commande Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01007",
        "autorisation": "Consulter Reception ",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02007",
        "autorisation": "Editer Reception ",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03007",
        "autorisation": "Valider Reception ",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "04007",
        "autorisation": "Solder Reception ",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01008",
        "autorisation": "Consulter Retour Fournisseur",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02008",
        "autorisation": "Editer Retour Fournisseur",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03008",
        "autorisation": "Valider Retour Fournisseur",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "04008",
        "autorisation": "Solder Retour Fournisseur",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "05008",
        "autorisation": "Decision Bon Retour Fournisseur",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01009",
        "autorisation": "Consulter Expedition",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02009",
        "autorisation": "Editer Expedition",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03009",
        "autorisation": "Valider Expedition",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "04009",
        "autorisation": "Solder Expedition",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01010",
        "autorisation": "Consulter Retour Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02010",
        "autorisation": "Editer Retour Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03010",
        "autorisation": "Valider Retour Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "04010",
        "autorisation": "Solder Retour Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "05010",
        "autorisation": "Decision Bon Retour Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01011",
        "autorisation": "Consulter Transfert entre Depot MP",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02011",
        "autorisation": "Editer Transfert entre Depot MP",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03011",
        "autorisation": "Valider Transfert entre Depot MP",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01012",
        "autorisation": "Consulter Facture Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02012",
        "autorisation": "Editer Facture Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03012",
        "autorisation": "Valider Facture Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "04012",
        "autorisation": "Solder Facture Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01013",
        "autorisation": "Consulter Facture Avoir Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02013",
        "autorisation": "Editer Facture Avoir Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03013",
        "autorisation": "Valider Facture Avoir Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "04013",
        "autorisation": "Solder Facture Avoir Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01014",
        "autorisation": "Consulter Facture Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02014",
        "autorisation": "Editer Facture Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03014",
        "autorisation": "Valider Facture Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "04014",
        "autorisation": "Solder Facture Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
   
    {
        "code": "01015",
        "autorisation": "Consulter Facture Avoir Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02015",
        "autorisation": "Editer Facture Avoir Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03015",
        "autorisation": "Valider Facture Avoir Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "04015",
        "autorisation": "Solder Facture Avoir Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01016",
        "autorisation": "Consulter Paiements",
        "module": "6",
        "__typename": "Autorisation"
    },
    {
        "code": "02016",
        "autorisation": "Editer Paiements",
        "module": "6",
        "__typename": "Autorisation"
    },
    {
        "code": "03016",
        "autorisation": "Valider Paiements",
        "module": "6",
        "__typename": "Autorisation"
    },
    {
        "code": "01017",
        "autorisation": "Consulter Encaissements",
        "module": "6",
        "__typename": "Autorisation"
    },
    {
        "code": "02017",
        "autorisation": "Editer Encaissements",
        "module": "6",
        "__typename": "Autorisation"
    },
    {
        "code": "03017",
        "autorisation": "Valider Encaissements",
        "module": "6",
        "__typename": "Autorisation"
    },
    {
        "code": "01018",
        "autorisation": "Consulter Modeles de Produit",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "02018",
        "autorisation": "Editer Modeles de Produit",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01019",
        "autorisation": "Consulter Attributs de Produit",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "02019",
        "autorisation": "Editer Attributs de Produit",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01020",
        "autorisation": "Consulter Offre Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02020",
        "autorisation": "Editer Offre Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03020",
        "autorisation": "Valider Offre Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "06020",
        "autorisation": "Annuler Offre Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "07020",
        "autorisation": "Convertir Offre Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
  
    {
        "code": "01021",
        "autorisation": "Consulter Service Fait Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02021",
        "autorisation": "Editer Service Fait Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03021",
        "autorisation": "Valider Service Fait Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "04021",
        "autorisation": "Solder Service Fait Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01022",
        "autorisation": "Consulter Proforma Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02022",
        "autorisation": "Editer Proforma Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03022",
        "autorisation": "Valider Proforma Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "06022",
        "autorisation": "Annuler Proforma Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "07022",
        "autorisation": "Convertir Proforma Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "08022",
        "autorisation": "Amender Proforma Client",
        "module": "2",
        "__typename": "Autorisation"
    },
   
    {
        "code": "01023",
        "autorisation": "Consulter Fiche de Production",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "02023",
        "autorisation": "Editer Fiche de Production",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "03023",
        "autorisation": "Valider Fiche de Production",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "04023",
        "autorisation": "Modifier Date Fiche de Production",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "01024",
        "autorisation": "Consulter Reception Interne",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02024",
        "autorisation": "Editer Reception Interne",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03024",
        "autorisation": "Valider Reception Interne",
        "module": "2",
        "__typename": "Autorisation"
    },
    
    {
        "code": "01025",
        "autorisation": "Consulter Situation des Clients",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01026",
        "autorisation": "Consulter Situation des Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01027",
        "autorisation": "Consulter Prix",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "02027",
        "autorisation": "Editer Prix",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01028",
        "autorisation": "Consulter Inventaire",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02028",
        "autorisation": "Editer Inventaire",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "06028",
        "autorisation": "Annuler Inventaire",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01029",
        "autorisation": "Consulter Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02029",
        "autorisation": "Editer Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03029",
        "autorisation": "Valider Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01030",
        "autorisation": "Consulter Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02030",
        "autorisation": "Editer Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03030",
        "autorisation": "Valider Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01031",
        "autorisation": "Consulter Bon Sortie Matière",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02031",
        "autorisation": "Editer Bon Sortie Matière",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03031",
        "autorisation": "Valider Bon Sortie Matière",
        "module": "3",
        "__typename": "Autorisation"
    },

    {
        "code": "01051",
        "autorisation": "Consulter Bon Sortie Magasin",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02051",
        "autorisation": "Editer Bon Sortie Magasin",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03051",
        "autorisation": "Valider Bon Sortie Magasin",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01052",
        "autorisation": "Consulter Bon Retour Magasin",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02052",
        "autorisation": "Editer Bon Retour Magasin",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03052",
        "autorisation": "Valider Bon Retour Magasin",
        "module": "3",
        "__typename": "Autorisation"
    },
    
    {
        "code": "01039",
        "autorisation": "Suivi des Receptions",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01032",
        "autorisation": "Consulter Bon réintragation",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02032",
        "autorisation": "Editer Bon réintragation",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03032",
        "autorisation": "Valider Bon réintragation",
        "module": "3",
        "__typename": "Autorisation"
    },

    {
        "code": "01033",
        "autorisation": "Consulter Depots",
        "module": "-1",
        "__typename": "Autorisation"
    },
    {
        "code": "02033",
        "autorisation": "Editer Depots",
        "module": "-1",
        "__typename": "Autorisation"
    },
    {
        "code": "03033",
        "autorisation": "Valider Depots",
        "module": "-1",
        "__typename": "Autorisation"
    },
    {
        "code": "01034",
        "autorisation": "Consulter Produits (GSM)",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02034",
        "autorisation": "Editer Produits (GSM)",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03034",
        "autorisation": "Valider Produits (GSM)",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01035",
        "autorisation": "Consulter Ordres de Fabrication ",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "02035",
        "autorisation": "Lancer Ordre de Fabrication ",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "03035",
        "autorisation": "Valider Ordre de Fabrication ",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "04035",
        "autorisation": "Solder Ordre de Fabrication ",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "01038",
        "autorisation": "Consulter Suivi Commandes Clients",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01036",
        "autorisation": "Consulter Commande Interne",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02036",
        "autorisation": "Lancer Commande Interne",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03036",
        "autorisation": "Valider Commande Interne",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "04036",
        "autorisation": "Solder Commande Interne",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01037",
        "autorisation": "Consulter Suivi Commandes Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
  
    {
        "code": "01040",
        "autorisation": "Consulter Suivi Factures Clients",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01041",
        "autorisation": "Consulter Suivi DP Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01042",
        "autorisation": "Consulter Suivi BL Clients",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01043",
        "autorisation": "Consulter Suivi BL Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01044",
        "autorisation": "Consulter Bon de reception OF interne",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02044",
        "autorisation": "Editer Bon de reception OF interne",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03044",
        "autorisation": "Valider Bon de reception OF interne",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01045",
        "autorisation": "Consulter Reforme",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02045",
        "autorisation": "Editer Reforme",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03045",
        "autorisation": "Valider Reforme",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01046",
        "autorisation": "Consulter Cessation",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02046",
        "autorisation": "Editer Cessation",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03046",
        "autorisation": "Valider Cessation",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01047",
        "autorisation": "Consulter Pret",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02047",
        "autorisation": "Editer Pret",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03047",
        "autorisation": "Valider Pret",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01048",
        "autorisation": "Consulter Situation des achats",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01049",
        "autorisation": "Consulter Situation des ventes",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01050",
        "autorisation": "Consulter Chargements",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02050",
        "autorisation": "Editer Chargements",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03050",
        "autorisation": "Valider Chargements",
        "module": "2",
        "__typename": "Autorisation"
    },    
    {
        "code": "05021",
        "autorisation": "BL/Facture",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01053",
        "autorisation": "Consulter Stock Vente",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01054",
        "autorisation": "Consulter Commande Client En Retard",
        "module": "2",
        "__typename": "Autorisation"
    },    
    {
        "code": "01055",
        "autorisation": "Consulter Facture Echue",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01056",
        "autorisation": "Consulter Of En Retard",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01057",
        "autorisation": "Consulter Stock GSM",
        "module": "3",
        "__typename": "Autorisation"
    },
    
    {
        "code": "01058",
        "autorisation": "Attribuer Tier",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01059",
        "autorisation": "Consulter Commande Fournisseur En Retard",
        "module": "0",
        "__typename": "Autorisation"
    },    
    {
        "code": "02059",
        "autorisation": "Modifier SI des Produits",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01060",
        "autorisation": "Consulter historique des documents",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01061",
        "autorisation": "Consulter documents attachés",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01062",
        "autorisation": "Consulter Reception Non Conforme ",
        "module": "5",
        "__typename": "Autorisation"
    },
    {
        "code": "02062",
        "autorisation": "Editer Reception Non Conforme ",
        "module": "5",
        "__typename": "Autorisation"
    },
    {
        "code": "03062",
        "autorisation": "Valider Reception Non Conforme ",
        "module": "5",
        "__typename": "Autorisation"
    },
    {
        "code": "01063",
        "autorisation": "Consulter Stock Non Conforme",
        "module": "5",
        "__typename": "Autorisation"
    },
    {
        "code": "02064",
        "autorisation": "Modifier Tolérence DMS",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01065",
        "autorisation": "Consulter Stock Production",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "01066",
        "autorisation": "Config. Production",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "01067",
        "autorisation": "Libre Facturation",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01068",
        "autorisation": "Déverrouiller Document",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01069",
        "autorisation": "Consulter Dossiers Import",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02069",
        "autorisation": "Editer Dossiers Import",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03069",
        "autorisation": "Valider Dossiers Import",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01070",
        "autorisation": "Consulter Demande d'Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02070",
        "autorisation": "Editer Demande d'Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03070",
        "autorisation": "Valider Demande d'Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "04070",
        "autorisation": "Solder Demande d'Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "05070",
        "autorisation": "Convertir Demande d'Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01071",
        "autorisation": "Consulter Consultations d'Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02071",
        "autorisation": "Editer Consultations d'Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03071",
        "autorisation": "Valider Consultations d'Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "04071",
        "autorisation": "Clotûrer Consultations d'Achat",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02072",
        "autorisation": "Creation Produit Standard",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01073",
        "autorisation": "Consulter DashBoard Commercial",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01074",
        "autorisation": "Editer Table de Base",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01075",
        "autorisation": "Editer Categories",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01076",
        "autorisation": "Consulter Contrats Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02076",
        "autorisation": "Editer Contrats Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03076",
        "autorisation": "Valider Contrats Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "04076",
        "autorisation": "Solder Contrats Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01077",
        "autorisation": "Consulter Contrats Clients",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02077",
        "autorisation": "Editer Contrats Clients",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03077",
        "autorisation": "Valider Contrats Clients",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "04077",
        "autorisation": "Solder Contrats Clients",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01078",
        "autorisation": "Consulter Suivi Contrats Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01079",
        "autorisation": "Consulter Suivi Contrats Clients",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01080",
        "autorisation": "Consulter Cautions Clients",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02080",
        "autorisation": "Editer Cautions Clients",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03080",
        "autorisation": "Valider Cautions Clients",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "04080",
        "autorisation": "Liberer Cautions Clients",
        "module": "6",
        "__typename": "Autorisation"
    },
    {
        "code": "01081",
        "autorisation": "Consulter Cautions Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02081",
        "autorisation": "Editer Cautions Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03081",
        "autorisation": "Valider Cautions Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "04081",
        "autorisation": "Liberer Cautions Fournisseurs",
        "module": "6",
        "__typename": "Autorisation"
    },
    {
        "code": "01082",
        "autorisation": "Consulter Bons de Travail ",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "02082",
        "autorisation": "Lancer Bons de Travail ",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "03082",
        "autorisation": "Valider Bons de Travail ",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "04082",
        "autorisation": "Solder Bons de Travail ",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "01083",
        "autorisation": "Consulter DP Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02083",
        "autorisation": "Editer DP Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03083",
        "autorisation": "Valider DP Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "04083",
        "autorisation": "Solder DP Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },    
    {
        "code": "01084",
        "autorisation": "Consulter Convention Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02084",
        "autorisation": "Editer Convention Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03084",
        "autorisation": "Valider Convention Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "04084",
        "autorisation": "Solder Convention Fournisseur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01085",
        "autorisation": "Consulter Convention Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02085",
        "autorisation": "Editer Convention Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03085",
        "autorisation": "Valider Convention Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "04085",
        "autorisation": "Solder Convention Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01086",
        "autorisation": "Consulter Demande d'Offre",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02086",
        "autorisation": "Editer Demande d'Offre",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03086",
        "autorisation": "Valider Demande d'Offre",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "04086",
        "autorisation": "Annuler Demande d'Offre",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "05086",
        "autorisation": "Convertir Demande d'Offre",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "06086",
        "autorisation": "Traiter Demande d'Offre",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01087",
        "autorisation": "Déverrouiller Mouvement",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01088",
        "autorisation": "Receptionner Sans Commande",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01089",
        "autorisation": "Suivi des consommations",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01090",
        "autorisation": "Consulter Bon d'entree Matière'",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "02090",
        "autorisation": "Editer Bon d'entree Matière",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "03090",
        "autorisation": "Valider Bon d'entree Matière",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01091",
        "autorisation": "Consulter Bon d'entree Produit'",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02091",
        "autorisation": "Editer Bon d'entree Produit",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03091",
        "autorisation": "Valider Bon d'entree Produit",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01092",
        "autorisation": "Livraison Sans Commande",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02092",
        "autorisation": "Corriger le stock",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "01093",
        "autorisation": "Consulter Bon Sortie Magasin",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02093",
        "autorisation": "Editer Bon Sortie Magasin",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03093",
        "autorisation": "Valider Bon Sortie Magasin",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01094",
        "autorisation": "Consulter Bon Retour Magasin",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02094",
        "autorisation": "Editer Bon Retour Magasin",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03094",
        "autorisation": "Valider Bon Retour Magasin",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01095",
        "autorisation": "Consulter Encours Production",
        "module": "0",
        "__typename": "Autorisation"
    },
    {
        "code": "02095",
        "autorisation": "Supprimer Encours Production",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "01096",
        "autorisation": "Consulter Intrants Production",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "02096",
        "autorisation": "Supprimer Intrants Production",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "01097",
        "autorisation": "Consulter Produits Périssable MP",
        "module": "3",
        "__typename": "Autorisation"
    },
    {
        "code": "01098",
        "autorisation": "Consulter Produits Périssable PF",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01099",
        "autorisation": "Consulter Transfert entre Depot PF",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02099",
        "autorisation": "Editer Transfert entre Depot PF",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03099",
        "autorisation": "Valider Transfert entre Depot PF",
        "module": "2",
        "__typename": "Autorisation"
    },
    
    {
        "code": "01100",
        "autorisation": "Consulter Service Fait Vente",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02100",
        "autorisation": "Editer Service Fait Vente",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03100",
        "autorisation": "Valider Service Fait Vente",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "04100",
        "autorisation": "Solder Service Fait Vente",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01101",
        "autorisation": "Cloturer Journal",
        "module": "6",
        "__typename": "Autorisation"
    },
    {
        "code": "01102",
        "autorisation": "Consulter Suivi facture Fournisseurs",
        "module": "1",
        "__typename": "Autorisation"
    },
     {
        "code": "01103",
        "autorisation": "Editer Documents",
        "module": "7",
        "__typename": "Autorisation"
    },
    {
        "code": "01104",
        "autorisation": "Editer Groupes",
        "module": "7",
        "__typename": "Autorisation"
    },
    {
        "code": "01105",
        "autorisation": "Editer Categories Documents",
        "module": "7",
        "__typename": "Autorisation"
    },
    {
        "code": "01106",
        "autorisation": "Consulter Simulateur",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01107",
        "autorisation": "Consulter DP Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "02107",
        "autorisation": "Editer DP Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "03107",
        "autorisation": "Valider DP Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "04107",
        "autorisation": "Solder DP Client",
        "module": "2",
        "__typename": "Autorisation"
    },
    {
        "code": "01108",
        "autorisation": "Consulter Demandes Paiements",
        "module": "6",
        "__typename": "Autorisation"
    },   
    {
        "code": "05108",
        "autorisation": "Accepter/Rejeter Demandes Paiements",
        "module": "6",
        "__typename": "Autorisation"
    },
    
    {
        "code": "01110",
        "autorisation": "Consulter Flash Production",
        "module": "4",
        "__typename": "Autorisation"
    },
    {
        "code": "02110",
        "autorisation": "Editer Flash Production",
        "module": "4",
        "__typename": "Autorisation"
    },
]
/*  export  function subTotal(data_list) {
    var total = data_list
      ? data_list.reduce(function(accumulator, item) {
          return accumulator + item.pu * item.qte;
        }, 0)
      : 0;
    return total;
  }

  export  function taxTotal(data_list) {
    let taxs = [];
    if (data_list ) {
        data_list.forEach((element) => {
          if (element.exe_tva == 0) {
          let i = taxs.findIndex((elm) => elm.taux == element.tva_tx);
          if (i >= 0)
            taxs[i].tva =
              taxs[i].tva + (element.pu * element.qte * element.tva_tx) / 100;
          else
            taxs.push({
              taux: element.tva_tx,
              tva: (element.pu * element.qte * element.tva_tx) / 100,
            });
        }
      });
    }
    return taxs;
  }
 
  export  function timbre(modepaiement,data_list) {
    var timbre = 0;
    if (modepaiement == "4") {
      let taxs = 0;
      taxTotal(data_list).forEach((element) => {
        taxs = taxs + element.tva;
      });

      timbre = Math.ceil((subTotal(data_list) + taxs) / 100);
      if (timbre < 5) timbre = 5;
      if (timbre > 2500) timbre = 2500;
    }
    return timbre;
  }
  export  function ttc(mode,data_list) {
    let taxs = 0;
    taxTotal(data_list).forEach((element) => {
      taxs = taxs + element.tva;
    });

    var total = subTotal(data_list) + taxs + timbre(mode,data_list);
    return total;
  }
  export function garantie(data_list) {
    let grt = [];
    data_list.forEach((element) => {
      if (element.garantie > 0) {
        let i = grt.findIndex((elm) => elm.taux == element.garantie);
        if (i >= 0)
          grt[i].garantie =
            grt[i].garantie +
            (element.pu * element.qte * element.garantie) / 100;
        else
          grt.push({
            taux: element.garantie,
            garantie: (element.pu * element.qte * element.garantie) / 100,
          });
      }
    });
    return grt;
  }
  export  function avc_cmds(data_list,payes_list) {
    let avc = [];   
    data_list.forEach((element) => {
      let j = payes_list.findIndex(
        (elm) =>
          elm.cmd_id == element.cmd_id && elm.avance == "1" && elm.reste > 0
      );
      
      if (element.avance > 0 && j >= 0) {
        let avance = (element.pu * element.qte * element.avance) / 100;

        if (avance > payes_list[j].reste) avance = payes_list[j].reste;

        let i = avc.findIndex((elm) => elm.cmd_id == element.cmd_id);
        if (i >= 0) avc[i].avance = avc[i].avance + avance;
        else {
          let title =
            "Rembourssement Avance Forfaitaire (" + element.avance + "%)";
          avc.push({
            paye_id: payes_list[j].id,
            title: title,
            cmd_id: element.cmd_id,
            taux: element.avance,
            avance: avance,
          });
        }
      }
    });
    
    return avc;
  }
 export function grandTotal(mode,data_list,payes_list) {
    let taxs = 0;
    taxTotal(data_list).forEach((element) => {
      taxs = taxs + element.tva;
    });
    let grts = 0;
      garantie(data_list).forEach((element) => {
        grts = grts + element.garantie;
      });
      let avcs = 0;
      avc_cmds(data_list,payes_list).forEach((element) => {
        avcs = avcs + element.avance;
      });
    var total = subTotal(data_list) + taxs + timbre(mode,data_list)-grts-avcs;
    return total;
  }*/