<template>
  <v-theme-provider light>
    <v-card
      v-if="editedItem.id > 0 || editedItem.id == -1"
      elevation="4"
      :key="kdoc"
    >
      <v-toolbar
        :disabled="save_disable"
        dense
        floating
        class="mt-n2 ml-n2"
        color="#73DDFF"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="valider_pfm"
              v-if="
                valider &&
                editedItem.statut_id == '1' &&
                detail_list.length > 0 &&
                grandTotal > 0 &&
                !edit
              "
              v-bind="attrs"
              v-on="on"
              :disabled="save_disable"
              ><v-icon left> mdi-check-bold </v-icon>
              Valider
            </v-btn>
          </template>
          <span>Valider Proforma</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="convertir_pfm"
              v-if="convertir && editedItem.statut_id == '2'"
              v-bind="attrs"
              v-on="on"
              :disabled="save_disable"
            >
              <v-icon> mdi-file-move </v-icon>
              Convertir
            </v-btn>
          </template>
          <span>Transformer proforma en Commande</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="amender_pfm"
              v-if="amender && editedItem.statut_id == '2' && tiertype == 1"
              v-bind="attrs"
              v-on="on"
              :disabled="save_disable"
            >
              <v-icon> mdi-file-replace </v-icon>
              Amender
            </v-btn>
          </template>
          <span>Annuler et remplacer cette proforma </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="annuler_pfm"
              v-if="annuler && editedItem.statut_id == '2'"
              v-bind="attrs"
              v-on="on"
              :disabled="save_disable"
            >
              <v-icon> mdi-file-cancel </v-icon>
              Annuler
            </v-btn>
          </template>
          <span>Annuler {{ tiertype == 1 ? "Proforma" : "Offre" }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="deletePfm"
              v-if="
                editer &&
                editedItem.statut_id == '1' &&
                detail_list.length == 0 &&
                !edit
              "
              :disabled="editedItem.nbdocs > 0 || save_disable"
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-delete </v-icon>
              {{ editedItem.id > 0 ? "Supprimer" : "Annuler" }}
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="modifier"
              v-if="editer && editedItem.statut_id == '1' && !edit"
              v-bind="attrs"
              v-on="on"
              :disabled="save_disable"
              ><v-icon left> mdi-pencil </v-icon>
              Modifier
            </v-btn>
          </template>
          <span>Editer pour modification</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="cancel"
              v-if="editer && edit"
              v-bind="attrs"
              v-on="on"
              :disabled="save_disable"
              ><v-icon> mdi-close </v-icon> Annuler</v-btn
            >
          </template>
          <span>Annuler les Modifications</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="save"
              v-if="editer && edit"
              v-bind="attrs"
              v-on="on"
              :disabled="save_disable"
              ><v-icon left> mdi-content-save-outline </v-icon>
              Enregistrer
            </v-btn>
          </template>
          <span>Enregistrer les modifications</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="printPfm"
              v-if="editedItem.statut_id >= '2' && tiertype == 1 && !edit"
              v-bind="attrs"
              v-on="on"
              :disabled="save_disable"
              ><v-icon> mdi-printer </v-icon>
            </v-btn>
          </template>
          <span>Imprimer</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="
                editedItem.id > 0 &&
                ($store.state.auth.includes('01061') || $store.state.isadmin) &&
                !edit
              "
              @click="OpenDocList"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-paperclip </v-icon>
            </v-btn>
          </template>
          <span>Documents Attachés</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="unlock"
              v-if="
                ($store.state.auth.includes('01068') || $store.state.isadmin) &&
                editedItem.statut_id == '2' &&
                !edit
              "
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-lock-open-variant </v-icon>
            </v-btn>
          </template>
          <span>Déverrouiller</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="
                editedItem.id > 0 &&
                ($store.state.auth.includes('01060') || $store.state.isadmin) &&
                !edit
              "
              @click.stop="drawer = !drawer"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-clipboard-text-clock-outline </v-icon>
            </v-btn>
          </template>
          <span>Historique Document</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="editedItem.id > 0 && editer"
              @click.stop="drawer2 = !drawer2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-notebook-edit-outline </v-icon>
            </v-btn>
          </template>
          <span>Suivi Proforma</span>
        </v-tooltip>
      </v-toolbar>
      <v-progress-linear
        v-if="progress || loading"
        indeterminate
        color="light-green darken-4"
        height="10"
        striped
      ></v-progress-linear>
      <v-card class="px-4 scroll" max-height="850">
        <v-card-text>
          <v-row>
            <v-col cols="12" sd="7" md="7" align-self="start">
              <img :src="logo" alt="Company Logo" />
            </v-col>
            <v-col cols="12" sd="5" md="5">
              <v-alert v-model="alert" :type="type_alert" dismissible>{{
                message
              }}</v-alert>
            </v-col>
            <!-- <v-col
              cols="12"
              sd="9"
              md="9"
              align-self="center"
              class="text-center"
            >
              <h1>{{ myname }}</h1>
            </v-col> -->
          </v-row>

          <!-- <v-row class="d-flex justify-space-around">
          <span>{{ "I.F. " + myif }}</span>
          <span>{{ "A.I. " + myart }}</span>
          <span>{{ "R.C. " + myrc }}</span>
          <span>
            {{
              editedItem.compte == null ? "" : "C.B. " + editedItem.compte
            }}</span
          >
        </v-row> -->
          <v-divider></v-divider>

          <v-form ref="form" v-model="valid" lazy-validation class="mt-6">
            <v-row>
              <v-col cols="12" sd="6" md="6" lg="6">
                <v-row>
                  <v-col cols="12" sd="11" md="11">
                    <v-row>
                      <v-col
                        cols="11"
                        sm="11"
                        md="11"
                        lg="11"
                        v-if="edit && tiertype == 2"
                      >
                        <v-autocomplete
                          v-model="editedItem.cons_id"
                          :item-value="'id'"
                          :items="allconsults"
                          :filter="customFilter"
                          :label="'Consultation'"
                          :clearable="
                            modify && editedItem.pfmdetails.length == 0 && edit
                          "
                          dense
                          @change="new_tier = false"
                          :readonly="
                            !modify || editedItem.pfmdetails.length > 0 || !edit
                          "
                          @input="cs++"
                        >
                          <template v-slot:selection="{ item }">
                            <!-- HTML that describe how select should render selected items -->
                            {{
                              item.no_cons +
                              (item.label ? "-" + item.label : "")
                            }}
                          </template>
                          <template v-slot:item="{ item }">
                            <!-- HTML that describe how select should render selected items -->
                            {{
                              item.no_cons +
                              (item.label ? "-" + item.label : "")
                            }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="11"
                        sm="11"
                        md="11"
                        lg="11"
                        v-if="!editedItem.cons_id && edit"
                      >
                        <v-checkbox
                          :label="
                            'Nouveau ' +
                            (tiertype == 1 ? 'Client' : 'Fournisseur')
                          "
                          v-model="new_tier"
                          dense
                          @change="new_tier_click"
                          :readonly="!edit || editedItem.pfmdetails.length > 0"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="11"
                        sm="11"
                        md="11"
                        lg="11"
                        v-if="edit && new_tier"
                      >
                        <v-text-field
                          ref="ref"
                          autocomplete="off"
                          dense
                          class="inputPrice"
                          v-model="editedItem.tier_name"
                          :label="tiertype == 1 ? 'Client' : 'Fournisseur'"
                          :rules="[
                            (v) =>
                              !!v ||
                              (tiertype == 1 ? 'Client' : 'Fournisseur') +
                                ' obligatoire',
                          ]"
                          :readonly="!edit || editedItem.pfmdetails.length > 0"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="11"
                        sm="11"
                        md="11"
                        lg="11"
                        v-if="edit && !new_tier"
                      >
                        <cursorselect
                          :Qsearch="tierscursor"
                          :Qresp="'tiers_cursor'"
                          :value="tier_id"
                          :text_fields="[
                            'code_client',
                            'code_fournisseur',
                            'nom',
                          ]"
                          :variableadd="variableadd"
                          :list_items="editedItem.cons_id ? tiers_list : null"
                          :item_text="'tier_name'"
                          @change="tierChange"
                          :label="tiertype == 1 ? 'Client' : 'Fournisseur'"
                          :readonly="
                            !modify ||
                            (editedItem.pfmdetails.length > 0 &&
                              editedItem.tier_id > 0)
                          "
                          :key="cs"
                        >
                        </cursorselect>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="1"
                        md="1"
                        lg="1"
                        v-if="!editedItem.cons_id"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="
                                modify &&
                                edit_tier &&
                                edit &&
                                editedItem.pfmdetails.length == 0
                              "
                              depressed
                              @click.stop="newtier"
                              class="mt-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon> mdi-plus </v-icon>
                            </v-btn>
                          </template>
                          <span
                            >Ajouter
                            {{ tiertype == 1 ? "Client" : "Fournisseur" }}</span
                          >
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    class="text-left"
                    v-if="!edit && tiertype == 2 && editedItem.cons_id"
                  >
                    Consultation
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="8"
                    class="text-left h5"
                    v-if="!edit && tiertype == 2 && editedItem.cons_id"
                  >
                    {{
                      editedItem.no_cons +
                      (editedItem.label_cons ? "-" + editedItem.label_cons : "")
                    }}
                  </v-col>
                  <v-col cols="12" sd="2" md="4" class="text-left" v-if="!edit">
                    {{ tiertype == 1 ? "Client" : "Fournisseur" }}
                  </v-col>

                  <v-col cols="12" sd="2" md="8" v-if="!edit">
                    <a
                      @click="newtier"
                      v-if="
                        editedItem.tier_id == null &&
                        editedItem.statut_id == '2' &&
                        edit_tier
                      "
                    >
                      <strong
                        ><h3>{{ editedItem.tier_name }}</h3>
                      </strong>
                    </a>
                    <div v-else>
                      <strong
                        ><h3>{{ editedItem.tier_name }}</h3></strong
                      >
                    </div>
                  </v-col>
                  <v-row v-if="!new_tier" no-gutters>
                    <v-col cols="12" sd="2" md="4"> Code </v-col>
                    <v-col cols="12" sd="2" md="8">
                      <a @click="gotier" v-if="consult_tier">
                        {{ editedItem.code_tier }}
                      </a>
                      <div v-else>
                        {{ editedItem.code_tier }}
                      </div>
                    </v-col>

                    <v-col cols="12" sd="2" md="4"> Adresse</v-col>
                    <v-col cols="12" sd="2" md="8">
                      {{ editedItem.adresse }}
                    </v-col>

                    <v-col
                      cols="12"
                      sd="2"
                      md="4"
                      v-if="editedItem.currency_id == 114"
                    >
                      R.C. N°</v-col
                    >
                    <v-col
                      cols="12"
                      sd="2"
                      md="8"
                      v-if="editedItem.currency_id == 114"
                    >
                      {{ editedItem.RC }}
                    </v-col>

                    <v-col
                      cols="12"
                      sd="2"
                      md="4"
                      v-if="editedItem.currency_id == 114"
                      >N° Article</v-col
                    >
                    <v-col
                      cols="12"
                      sd="2"
                      md="8"
                      v-if="editedItem.currency_id == 114"
                    >
                      {{ editedItem.ART }}
                    </v-col>

                    <v-col
                      cols="12"
                      sd="2"
                      md="4"
                      v-if="editedItem.currency_id == 114"
                      >Id Fiscal</v-col
                    >
                    <v-col
                      cols="12"
                      sd="2"
                      md="8"
                      v-if="editedItem.currency_id == 114"
                    >
                      {{ editedItem.NIF }}
                    </v-col>

                    <v-col
                      cols="12"
                      sd="2"
                      md="4"
                      v-if="editedItem.currency_id == 114"
                      >N° Compte</v-col
                    >
                    <v-col
                      cols="12"
                      sd="2"
                      md="8"
                      v-if="editedItem.currency_id == 114"
                    >
                      {{ editedItem.cmpt }}
                    </v-col>
                  </v-row>
                </v-row>
              </v-col>

              <v-col cols="12" sd="2" md="6" lg="6">
                <v-row>
                  <v-col cols="12" sd="12" md="12">
                    <h2>{{ formTitle }}</h2>
                    <br />
                    <strong> Date : {{ datefr(editedItem.date_pfm) }}</strong>
                    <br />
                    <strong v-if="!edit">
                      Ref.Ext. : {{ editedItem.pfm_ref }}</strong
                    >
                    <br />
                    <strong v-if="!edit && editedItem.dem_id">
                      Selon demande d'offre N° :
                      <a @click="goto_demande">{{
                        editedItem.no_da
                      }}</a></strong
                    >
                    <br />
                    <strong v-if="!edit && editedItem.parent_id">
                      En remplacement de la proforma N° :
                      <a @click="goto_proforma">{{
                        editedItem.nopfm_org
                      }}</a></strong
                    >
                    <strong v-if="!edit && editedItem.nocmd">
                      Convertie en bon de commande N° :
                      <a @click="goto_cmd">{{ editedItem.nocmd }}</a></strong
                    >
                  </v-col>

                  <v-col cols="12" sd="12" md="12" lg="12">
                    <v-row dense v-if="edit">
                      <v-col cols="12" sd="4" md="4" class="text-left">
                        <v-text-field
                          ref="ref"
                          autocomplete="off"
                          dense
                          class="inputPrice"
                          v-model="editedItem.pfm_ref"
                          label="Ref. Externe"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sd="4" md="8" class="text-left">
                        <v-row>
                          <v-col cols="12" sd="6" md="8">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              dense
                              class="inputPrice"
                              v-model.number="editedItem.delai"
                              :label="'Délai de livraison (Jours)'"
                              :rules="[
                                (v) => !!v || 'Délai de livraison obligatoire',
                              ]"
                              :readonly="!edit"
                              hide-spin-buttons
                              @focus="$event.target.select()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sd="4" md="3" class="text-left">
                        <v-text-field
                          autocomplete="off"
                          dense
                          class="inputPrice"
                          type="number"
                          v-model.number="editedItem.validite"
                          label="Validite (jours)"
                          :rules="[(v) => !!v || 'Validite obligatoire']"
                          hide-spin-buttons
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sd="6" md="5" class="text-left">
                        <v-select
                          dense
                          :items="modalites"
                          item-text="libelle"
                          item-value="id"
                          v-model="editedItem.modalite_id"
                          :rules="[
                            (v) => !!v || 'Modalité de Paiement obligatoire',
                          ]"
                          label="Modalité de Paiement"
                          :readonly="!edit"
                          @input="cmpt++"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sd="6" md="4" class="text-left">
                        <v-select
                          dense
                          :items="modes_list"
                          item-text="mode"
                          item-value="id"
                          v-model="editedItem.modepaiement"
                          :rules="[
                            (v) => !!v || 'Mode de Paiement obligatoire',
                          ]"
                          label="Mode de Paiement"
                          :readonly="!edit"
                          @input="cmpt++"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sd="4"
                        md="4"
                        class="text-left"
                        v-if="
                          editedItem.currency_id != 114 &&
                          editedItem.currency_id
                        "
                      >
                        <v-select
                          dense
                          :items="modestransports"
                          item-text="mode"
                          item-value="id"
                          v-model="editedItem.modetransport"
                          label="Mode de Transport"
                          :rules="[
                            (v) => !!v || 'Mode de Transport obligatoire',
                          ]"
                          :readonly="!edit"
                          @input="cmpt++"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sd="2"
                        md="3"
                        class="text-left"
                        v-if="
                          editedItem.currency_id != 114 &&
                          editedItem.currency_id
                        "
                      >
                        <v-select
                          dense
                          :items="allincotermes"
                          item-text="libelle"
                          item-value="id"
                          v-model="editedItem.incoterme"
                          label="Incoterme"
                          :rules="[(v) => !!v || 'Incoterme obligatoire']"
                          :readonly="!edit"
                          :disabled="!editedItem.modetransport"
                          @input="cmpt++"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sd="4"
                        md="3"
                        class="text-left"
                        v-if="
                          editedItem.currency_id != 114 &&
                          editedItem.currency_id
                        "
                      >
                        <v-select
                          dense
                          :items="ports_etr"
                          item-text="libelle"
                          item-value="id"
                          v-model="editedItem.port_id"
                          label="Lieu d\'embarquement"
                          :readonly="!edit"
                          :rules="[
                            (v) => !!v || 'Lieu d\'embarquement obligatoire',
                          ]"
                          :disabled="
                            !editedItem.modetransport ||
                            editedItem.modetransport >= '3'
                          "
                          @input="cmpt++"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        v-if="
                          editedItem.currency_id != 114 &&
                          editedItem.currency_id
                        "
                      >
                        <v-text-field
                          autocomplete="off"
                          class="inputPrice"
                          type="number"
                          dense
                          v-model.number="editedItem.mont_transport"
                          :label="'Frêt (' + editedItem.devise + ')'"
                          hide-spin-buttons
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sd="12" md="12" class="text-left">
                        <v-textarea
                          dense
                          v-model="editedItem.notes"
                          label="Notes"
                          :readonly="!edit"
                          @input="cmpt++"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sd="12" md="12" class="text-left">
                        <v-text-field
                          dense
                          v-model="editedItem.comment"
                          label="Commentaire"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12" sd="12" md="12" v-if="!edit">
              <listitems
                :list="detail_list"
                :title="'Produits'"
                :headers="products_headers"
                :filename="'Produits'"
                :sheetname="'Produits'"
                @btn_click="OpenProductForm"
                @open="OpenProductForm"
                :master="true"
                :showedit="false"
                :key="klist"
                :add="editer && editedItem.statut_id == 1 && editedItem.id > 0"
                :Update="editedItem.statut_id == '1' && editer"
                :del="editedItem.statut_id == '1' && editer"
                :elevation="0"
                @delete="deleteitem"
                :ipg="ipg"
                @options="send_option"
              >
              </listitems>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="!edit">
            <v-col cols="12" sd="6" md="8">
              <v-row no-gutters>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  VALIDITE DE L'OFFRE :
                  {{
                    editedItem.validite ? editedItem.validite + " jours" : ""
                  }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  DELAI DE LIVRAISON

                  {{
                    editedItem.delai
                      ? editedItem.delai + " jours date Bon de Commande"
                      : ""
                  }}
                </v-col>
                <v-col cols="12" sm="6" md="12" v-if="editedItem.garantie">
                  Retenue Garantie(%) : {{ editedItem.garantie }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left r">
                  MODALITE DE PAIEMENT :
                  {{ modalite }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left r">
                  MODE DE PAIEMENT :
                  {{ mode }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="editedItem.modetransport"
                >
                  Mode de Transport :
                  {{ transport }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="editedItem.port_id"
                >
                  Port d'embarquement :
                  {{ port }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="editedItem.incoterme"
                >
                  INCOTERME :
                  {{ editedItem.incoterme }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  NB : {{ editedItem.notes }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Comment : {{ editedItem.comment }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Etablit Par : {{ editedItem.contact }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sd="6" md="4">
              <v-row no-gutters>
                <v-col cols="12" sd="6" md="4">
                  {{ editedItem.currency_id == 114 ? "Total H.T" : "Montant" }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold nowrap text-right pa-1"
                >
                  {{ numberWithSpace(decimalDigits(subTotal)) }}
                </v-col>
              </v-row>
              <v-row no-gutters v-if="editedItem.mont_transport">
                <v-col cols="12" sd="6" md="4"> Frêt </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold nowrap text-right pa-1"
                >
                  {{
                    numberWithSpace(decimalDigits(editedItem.mont_transport))
                  }}
                </v-col>
              </v-row>
              <v-row v-for="(tax, taux) in taxTotal" :key="taux" no-gutters>
                <v-col cols="12" sd="6" md="4"> T.V.A {{ tax.taux }} % </v-col>
                <v-col cols="12" sd="6" md="8" class="text-right pa-1">
                  {{ numberWithSpace(decimalDigits(tax.tva)) }}
                </v-col>
              </v-row>
              <v-row v-if="taxTotal.length > 1" no-gutters>
                <v-col cols="12" sd="6" md="4"> Total T.V.A </v-col>
                <v-col cols="12" sd="6" md="8" class="text-right pa-1">
                  {{ numberWithSpace(decimalDigits(sumtaxTotal("tva"))) }}
                </v-col>
              </v-row>
              <v-row
                v-if="
                  subTotal > 0 &&
                  sumtaxTotal('tva') == 0 &&
                  editedItem.currency_id == 114
                "
                no-gutters
              >
                <v-col cols="12" sd="6" md="4"> T.V.A</v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold text-right pa-1"
                >
                  EXONORE
                </v-col>
              </v-row>
              <v-row
                v-if="timbre > 0 && editedItem.currency_id == 114"
                no-gutters
              >
                <v-col cols="12" sd="6" md="4"> Droit de timbre (1%) </v-col>
                <v-col cols="12" sd="6" md="8" class="text-right pa-1">
                  {{ numberWithSpace(decimalDigits(timbre)) }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sd="6" md="4">
                  {{
                    editedItem.currency_id == 114
                      ? "Total T.T.C "
                      : "Total " +
                        (editedItem.devise ? "(" + editedItem.devise + ")" : "")
                  }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold nowrap text-right pa-1"
                >
                  {{ numberWithSpace(decimalDigits(grandTotal)) }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <confirmdialog
            ref="confirm"
            :is_motif="is_motif"
            @motif="get_motif"
          />

          <v-snackbar
            v-model="snackbar"
            :timeout="snackbar_timeout"
            top
            :color="snackbar_color"
          >
            {{ snackbar_text }}
          </v-snackbar>

          <pfmdetailform
            :item="product"
            :items="detail_list"
            :readonly="editedItem.statut_id != '1'"
            :tiertype="tiertype"
            :key="dkey"
            :showForm="!isDetailClosed"
            :exe_tva="editedItem.exe_tva == '1'"
            @close="closeDetailForm"
            :tier_type_prix="tier_type_prix"
            :units="units"
            :qte_prix="qte_prix"
            :loading="loading"
            :pfm="editedItem"
            :tier_products="tier_products"
          >
          </pfmdetailform>

          <filelist
            :item="editedItem"
            :showform="!isDocListClose"
            :key="fdocl"
            @close="isDocListClose = true"
            :editer="editer"
            :doc_type="1"
            :auth="auth"
          >
          </filelist>

          <timeline
            :document_type="1"
            :document_id="parseInt(editedItem.id)"
            v-model="drawer"
            :operations="operations"
            :doc_type="'Proforma'"
            :doc_code="editedItem.nopfm"
          >
          </timeline>
          <v-navigation-drawer v-model="drawer2" absolute temporary width="600">
            <comments
              :title="'Suivi Proforma'"
              :comments="comments"
              :document_type="1"
              :doc_id="parseInt(editedItem.id)"
            ></comments>
          </v-navigation-drawer>
        </v-card-text>
      </v-card>
    </v-card>
  </v-theme-provider>
</template>
<script>
import DELETE_PRODUIT from "../graphql/Proforma/DELETE_PRODUIT.gql";
import DELETE_PFM from "../graphql/Proforma/DELETE_PFM.gql";
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";
import UPDATE_PFM from "../graphql/Proforma/UPDATE_PFM.gql";
import CREATE_PFM from "../graphql/Proforma/CREATE_PFM.gql";
import CREATE_CMD from "../graphql/Commande/CREATE_CMD.gql";
import CREATE_CMD_DETAIL from "../graphql/Proforma/CREATE_CMD_DETAIL.gql";
import CREATE_PFM_DETAIL from "../graphql/Demande/CREATE_PFM_DETAIL.gql";

import UPDATE_MODIF from "../graphql/UPDATE_MODIF.gql";
import ARTICLES from "../graphql/Commande/ARTICLES.gql";
import { doc_template } from "print/template.js";
import { datefr } from "@/utils/functions.js";
import { myLogo, myName, myIF, myRC, myART, grt } from "print/data.js";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    pfmdetailform: () => import("./PfmDetailForm.vue"),
    filelist: () => import("./FileList.vue"),
    listitems: () => import("./ListItems.vue"),
    comments: () => import("./Comment.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
    timeline: () => import("./Timeline.vue"),
  },
  props: {
    pfm: Object,
    tiertype: Number,
    tier_type_prix: Array,
    qte_prix: Array,
    loading: Boolean,
    editer: Boolean,
    amender: Boolean,
    valider: Boolean,
    annuler: Boolean,
    convertir: Boolean,
    edition: { type: Boolean, default: false },
    modes: Array,
    modalites: Array,
    ports: Array,
    allconsults: Array,
    units: Array,
    ipg: { type: Number, default: 10 },
  },
  data() {
    return {
      save_disable: false,
      drawer: null,
      drawer2: null,
      duedate: null,
      progress: false,
      valid: true,
      page: 1,
      numberRule: (v) => {
        if (!v || (!isNaN(parseFloat(v)) && v >= 0 && v <= 100)) return true;
        return "Nombre doit être entre 0 and 100";
      },
      edit_tier: false,
      new_tier: false,
      consult_tier: false,
      is_motif: false,
      modify: false,
      exe_tva: false,
      tier_id: null,
      isDocListClose: true,
      fdocl: 1000,
      isDetailClosed: true,
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      kdoc: 700,
      cs: 800,
      edit: false,
      detailcmdheaders: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "Produit",
          value: "label",
          selected: true,
        },
        {
          text: "Prix",
          value: "pu",
          selected: true,
          slot: "cur22",
          align: "end",
        },
        {
          text: "Qte Proforma",
          value: "qte_pfm",
          selected: true,
          slot: "cur22",
          align: "end",
        },
        {
          text: "Qte a commander",
          value: "qte",
          selected: true,
          slot: "numberedit",
          align: "end",
        },

        {
          text: "Supprimer",
          value: "actions",
          sortable: false,
          selected: true,
        },
      ],
      products_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          slot: "href",
          align: "left",
        },
        {
          text: "Produit",
          value: "label",
          column: "produit_id",
          selected: true,
          align: "left",
        },
        {
          text: "PU HT",
          value: "pu",
          slot: "cur",
          align: "end",
          width: "110px",
          selected: true,
        },
        {
          text: "Quantité",
          value: "qte",
          slot: "cur",
          align: "end",
          width: "110px",
          selected: true,
        },
        {
          text: "Unite",
          value: "unit",
          selected: true,
        },
        {
          text: "Montant HT",
          value: "ht",
          slot: "cur",
          align: "end",
          width: "150px",
          selected: true,
        },
        {
          text: "Avant le",
          value: "date_limite",
          selected: false,
          slot: "date",
        },
        {
          text: "Livraison",
          value: "adresse",
          selected: false,
        },
        {
          text: "Comment",
          value: "comment",
          selected: false,
        },
        {
          text: "",
          slot: "actions",
          selected: true,
          hiden: true,
        },
      ],
      defaultItem: {
        id: -1,
        status_name: "Brouillon",
        statut_id: "1",
        pfmdetails: [],
        docs: [],
        date_pfm: null,
        tot_liv: 0,
        notes: "",
        tier: {},
        currency_id: "114",
      },
      modestransports: [
        { id: "1", mode: "Aérien" },
        { id: "2", mode: "Maritime" },
        { id: "3", mode: "Terrestre" },
        { id: "4", mode: "Ferroviaire" },
      ],
      allincotermes: [
        "FAS",
        "CFR",
        "FOB",
        "FCA",
        "EXW",
        "CPT",
        "CIP",
        "DPU",
        "DAP",
        "DDP",
        "CIF",
      ],
      cmpt: 0,
      mc: 200,
      dkey: 100,
      pkey: 300,
      fl: 400,
      editedItem: {},
      product: {},
      cmd_list: [],
      tier_products: [],
      klist: 500,
      kdd: 1600,
      message: "",
      alert: false,
      type_alert: "error",
      pageInfo: {},
      loading2: false,
    };
  },

  watch: {},
  async created() {
    if (this.pfm.id) {
      this.edit = this.edition;
      if (this.pfm.id > 0) {
        this.editedItem = Object.assign({}, this.pfm);

        if (this.editedItem.statut_id == "3") {
          this.message = this.editedItem.motif ? this.editedItem.motif : "";
          this.alert = true;
        }
        if (this.editedItem.statut_id == "4") {
          this.message =
            "Convertie en commande N° : " +
            (this.editedItem.nocmd ? this.editedItem.nocmd : "");
          this.type_alert = "success";
          this.alert = true;
        }
        this.tier_id = this.editedItem.tier_id;
        if (this.editedItem.cons_id) {
          let i = this.tiers_list.findIndex(
            (elm) =>
              (this.editedItem.cons_id ? elm.tier_name : elm.nom) ==
              this.editedItem.tier_name
          );
          if (i >= 0) this.tier_id = this.tiers_list[i].id;
        }
        if (!this.editedItem.tier_id && !this.editedItem.cons_id) {
          this.new_tier = true;
          this.new_tier_click();
        }

        this.get_products_tier();
      } else {
        this.defaultItem.date_pfm = this.$store.state.today;
        this.editedItem = Object.assign({}, this.defaultItem);

        if (this.pfm.tier_id > 0) {
          this.editedItem.tier_id = this.pfm.tier_id;
        }
      }
    } else this.editedItem = Object.assign({});
    if (
      this.editedItem.statut_id == "2" &&
      this.editedItem.tier_id &&
      (this.$store.state.auth.includes("02003") || this.$store.state.isadmin)
    )
      this.products_headers[2].slot = "href99";
    this.products_headers[10].hiden = this.editedItem.statut_id != "1";
    this.products_headers[3].text =
      (this.editedItem.currency_id == 114 ? "PU HT" : "PU ") +
      (this.editedItem.devise ? "(" + this.editedItem.devise + ")" : "");
    this.modify = this.editer && this.editedItem.statut_id == "1";
    this.edit_tier =
      (this.tiertype == 0 && this.$store.state.auth.includes("02001")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("02029")) ||
      (this.tiertype == 1 && this.$store.state.auth.includes("02030")) ||
      this.$store.state.isadmin;
    this.consult_tier =
      (this.tiertype == 0 && this.$store.state.auth.includes("01001")) ||
      (this.tiertype == 1 && this.$store.state.auth.includes("01030")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("01029")) ||
      this.$store.state.isadmin;
  },
  methods: {
    send_option(item) {
      this.$emit("options", item);
    },
    new_tier_click() {
      if (this.new_tier) {
        this.editedItem.tier_id = null;

        this.editedItem.code_tier = null;
        this.editedItem.adresse = null;
        this.editedItem.currency_id = null;
        this.editedItem.tier = {};
        this.editedItem.RC = null;
        this.editedItem.ART = null;
        this.editedItem.NIF = null;
        this.editedItem.NIS = null;
      } else {
        this.editedItem.tier_name = null;
      }
    },
    goto_proforma() {
      this.$emit("goto", {
        id: this.editedItem.parent_id,
        nopfm: this.editedItem.nopfm_org,
      });
    },
    goto_cmd() {
      this.$router.push({
        name: "commandes_c",
        params: {
          pfm: this.pfm,
          cmd_id: this.editedItem.cmd_id,
          name: "proformas_c",
          filtre: this.editedItem.nocmd,
        },
      });
    },
    goto_demande() {
      this.$router.push({
        name: "offre",
        params: {
          pfm: this.pfm,
          dem_id: this.editedItem.dem_id,
          name: "proformas_c",
          filtre: this.editedItem.no_da,
        },
      });
    },
    async get_products_tier() {
      if (this.tiertype == 2) {
        //all articles
        if (!this.editedItem.cons_id) {
          let v = {
            tier_id: this.pfm.tier_id,
          };
          let r = await this.requette(ARTICLES, v);
          if (r) {
            this.tier_products = r.articles;
          }
        } else this.tier_products = this.clt_details;
      }
    },
    async verif_modif() {
      this.alert = false;
      let ok = false;
      let v = {
        table: "proformas",
        statut_id: "1",
        id: this.editedItem.id,
      };
      this.progress = true;
      let r = await this.maj(UPDATE_MODIF, v);
      this.progress = false;
      if (r) {
        let d = JSON.parse(r.UpdateModif);
        if (d.modif == 1) {
          this.alert = true;
          this.message =
            "Document ouvert en modification par " +
            d.name +
            " depuis le " +
            datefr(d.modif_date, true);
          this.type_alert = "warning";
        } else if (d.modif == 2) {
          this.alert = true;
          this.message = "Document Validé!! Actualiser vos données ";
          this.type_alert = "error";
        } else if (d.modif == -1) {
          this.alert = true;
          this.message = "Document introuvable ";
          this.type_alert = "error";
        } else {
          this.$store.dispatch("Document", v);
          this.$store.dispatch("Editing", true);
          ok = true;
        }
      }
      this.save_disable = false;
      return ok;
    },
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    customFilter(item, queryText) {
      let textlist = [];
      let l =
        this.tiertype == 1
          ? ["code_client", "nom"]
          : ["code_fournisseur", "nom"];
      l.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    async modifier() {
      if (await this.verif_modif()) {
        this.edit = true;
        this.$emit("edition", true);
        this.kdoc++;
      }
    },
    async deleteitem(item) {
      if (await this.verif_modif()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimmer cette ligne?",
            { color: "orange darken-3" }
          )
        ) {
          let r = await this.maj(DELETE_PRODUIT, { id: item.id });
          if (r) {
            this.detail_list.splice(item.index, 1);
            this.$store.dispatch("Changed", true);
            this.update_modif();
            this.kdoc++;
          }
        } else this.update_modif();
      }
      this.$store.dispatch("Editing", false);
    },
    async cancel() {
      let ok = true;
      if (
        (this.cmpt > 0 && this.editedItem.id < 0) ||
        (this.cmpt > 1 && this.editedItem.id > 0)
      ) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en-cours! Etês-vous sûr de vouloir annuler?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) {
        if (this.editedItem.id < 0) {
          this.$emit("cancel", this.editedItem);
          this.editedItem.id = null;
        }

        await this.update_modif();
        this.edit = false;
        this.$emit("edition", false);
        this.$store.dispatch("Changed", true);
        this.kdoc++;
      }
    },
    gotier() {
      if (this.tiertype == 1)
        this.$router.push({
          name: "clients",
          params: {
            pfm: this.pfm,
            tier_id: this.editedItem.tier_id,
            code_tier: this.editedItem.code_tier,
            tier_name: this.editedItem.tier_name,
            name: "proformas_c",
          },
        });
      if (this.tiertype == 2)
        this.$router.push({
          name: "fournisseurs",
          params: {
            pfm: this.pfm,
            tier_id: this.editedItem.tier_id,
            code_tier: this.editedItem.code_tier,
            tier_name: this.editedItem.tier_name,
            name: "proformas_f",
          },
        });
    },
    newtier() {
      this.$store.dispatch("Editing", false);
      if (this.tiertype == 1)
        this.$router.push({
          name: "clients",
          params: {
            pfm: this.pfm,
            tier_id: -1,
            tier_name: this.editedItem.tier_name,
            name: "proformas_c",
          },
        });
      if (this.tiertype == 2)
        this.$router.push({
          name: "fournisseurs",
          params: {
            pfm: this.pfm,
            tier_id: -1,
            tier_name: this.editedItem.tier_name,
            name: "proformas_f",
          },
        });
    },

    tierChange(item, l) {
      let i;
      let list = this.tiers_list;
      if (l) list = l;
      this.tier_id = item;
      if (this.tier_id) {
        i = list.findIndex((elm) => elm.id == this.tier_id);
        if (this.editedItem.id < 0) {
          this.editedItem.modepaiement = null;
          this.editedItem.modalite_id = null;
        }
        this.editedItem.code_tier = null;
        this.editedItem.adresse = null;
        this.editedItem.currency_id = null;
        this.editedItem.RC = null;
        this.editedItem.ART = null;
        this.editedItem.NIF = null;
        if (this.editedItem.tier) {
          this.editedItem.tier.statut_id = null;
        }

        if (i >= 0) {
          if (this.editedItem.cons_id) {
            this.editedItem.tier_id = list[i].tier_id;

            this.editedItem.tier_name = list[i].tier_name;
          } else {
            this.editedItem.tier_id = list[i].id;
          }
          if (this.editedItem.tier_id) {
            this.editedItem.code_tier =
              this.tiertype == 1
                ? list[i].code_client
                : list[i].code_fournisseur;
            this.editedItem.adresse = list[i].adresse;
            this.editedItem.tier = {};
            this.editedItem.RC = list[i].RC;
            this.editedItem.ART = list[i].ART;
            this.editedItem.NIF = list[i].NIF;
            this.editedItem.tier.statut_id = list[i].statut_id;
            this.editedItem.currency_id = list[i].currency_id;
            if (this.editedItem.id < 0) {
              this.editedItem.modepaiement = list[i].modepaiement;
              this.editedItem.modalite_id = list[i].modalite_id;
            }
            this.editedItem.devise = list[i].devise;
          }
        }
      }
      this.cmpt++;
    },
    OpenDocList() {
      this.isDocListClose = false;
      this.fdocl++;
    },
    async update_statut(st, motif) {
      let v = {
        input: {
          id: this.editedItem.id,
          statut_id: st,
          motif: motif,
        },
      };
      this.save_disable = true;
      let ok = await this.maj(UPDATE_PFM, v, true);
      this.save_disable = false;
      return ok;
    },
    async annuler_pfm() {
      this.is_motif = true;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir annuler cette proforma?"
        )
      ) {
        let ok = await this.update_statut(3);
        if (ok) this.editedItem.statut_id = 3;
      }
      this.is_motif = false;
      this.$store.dispatch("Editing", false);
    },
    audit() {},
    get_motif(m) {
      this.editedItem.motif = m;
    },
    async amender_pfm() {
      this.is_motif = true;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir amender cette proforma?",
          { color: "orange darken-3" }
        )
      ) {
        this.save_disable = true;
        let v;
        // créer proforma

        v = {
          input: {
            pfm_type: this.tiertype,
            tier_id: this.pfm.tier_id,
            statut_id: 1,
            comment: this.pfm.comment,
            parent_id: this.pfm.id,
            dem_id: this.pfm.dem_id,
            notes: "Annule et remplace la proforma N° " + this.pfm.nopfm,

            delai: parseInt(this.pfm.delai),
            tier_name: this.pfm.tier_id ? null : this.pfm.tier_name,

            create_uid: parseInt(this.$store.state.me.id),
            write_uid: parseInt(this.$store.state.me.id),
          },
        };
        let r = await this.maj(CREATE_PFM, v);
        if (r) {
          let pfm = r.createPfm;
          // créer detail proforma
          let l = [];
          for (let index = 0; index < this.detail_list.length; index++) {
            const element = this.detail_list[index];
            l.push({
              qte: parseFloat(element.qte),
              pfm_id: pfm.id,
              delai: element.delai,
              produit_id: element.produit_id,
              article_id: element.article_id,
              pu: element.pu,
              label: element.label,
              fk_unit: element.fk_unit,
              tva_tx: parseFloat(element.tva_tx),
              create_uid: this.$store.state.me.id,
              write_uid: this.$store.state.me.id,
            });
          }
          let new_comments = [];
          if (this.comments.length > 0) {
            this.comments.forEach((element) => {
              new_comments.push({
                document_id: parseInt(pfm.id),
                document_type: 1,
                comment_type: parseInt(element.comment_type),
                comment: element.comment,
                created_at: element.created_at,
                create_uid: element.create_uid,
              });
            });
            new_comments.push({
              document_id: parseInt(pfm.id),
              document_type: 1,
              comment_type: 4,
              comment:
                "------Fin Notes Proforma N° " +
                this.pfm.nopfm +
                "------------",
              created_at: new Date().toISOString().slice(0, 19),
              create_uid: this.$store.state.me.id,
            });
          }

          v = {
            input: l,
            pfm_id: pfm.id,
            comments: new_comments.length > 0 ? new_comments : null,
          };
          let r1 = await this.maj(CREATE_PFM_DETAIL, v);
          if (r1) {
            let v = {
              input: {
                id: this.editedItem.id,
                statut_id: 3,
                motif:
                  "Annulée et remplacée par la proforma N° " +
                  pfm.nopfm +
                  "\n Motif :" +
                  this.editedItem.motif,
                dem_id: null,
              },
            };
            this.save_disable = true;
            let ok = await this.maj(UPDATE_PFM, v, true);

            if (ok) this.editedItem.statut_id = 3;
          }
          this.$store.dispatch("Changed", true);
          this.$emit("change", pfm);
        }
        // }
      }
      this.save_disable = false;
    },
    async convertir_pfm() {
      let ok = true;
      if (!this.editedItem.tier_id) {
        this.message =
          this.editedItem.tier_name +
          " doit être crée avant confirmation proforma !!";
        this.alert = true;
        ok = false;
      }
      if (this.editedItem.statut_tier == "1") {
        this.message =
          this.editedItem.tier_name +
          " doit être validé avant confirmation proforma !!";
        this.alert = true;
        ok = false;
      }

      if (ok) {
        let i = this.detail_list.findIndex((elm) => elm.produit_id == null);
        if (i >= 0) {
          this.message =
            this.detail_list[i].label +
            " doit être crée avant confirmation proforma !!";
          this.alert = true;
          ok = false;
        }
      }
      if (ok) {
        let i = this.detail_list.findIndex((elm) => elm.product_statut == "1");
        if (i >= 0) {
          this.message =
            this.detail_list[i].label +
            " doit être validé avant confirmation proforma !!";
          this.alert = true;
          ok = false;
        }
      }
      if (ok && this.tiertype == 2) {
        let list;
        let v = {
          tier_id: this.pfm.tier_id,
        };
        let r = await this.requette(ARTICLES, v);
        if (r) {
          list = r.articles;
        }

        for (let index = 0; index < this.detail_list.length; index++) {
          const element = this.detail_list[index];
          let l = list.filter((elm) => elm.produit_id == element.produit_id);
          if (l.length == 0) {
            this.message =
              "Ce fournisseur ne dispose d'aucun article pour le produit " +
              element.label;
            this.alert = true;
            ok = false;
            break;
          } else if (l.length == 1) {
            element.article_id = l[0].id;
          }
        }
      }
      if (ok)
        if (
          await this.$refs.confirm.open(
            "Attention !",
            "Etês-vous sûr de vouloir convertir cette proforma en Bon de commande ?",
            { color: "orange darken-3" }
          )
        ) {
          this.save_disable = true;
          let v;
          this.editedItem.tol_qte =
            this.$store.state.options[0].tol_qte_cmd_plus;
          // créer commande
          v = {
            cmd: {
              cmd_type: this.tiertype,
              tier_id: this.pfm.tier_id,
              pfm_id: this.pfm.id,
              date_cmd: this.$store.state.today,
              sign_date: this.$store.state.today,
              statut_id: 6,
              date_opr: this.$store.state.today,
              comment: this.pfm.comment,
              currency_id: this.pfm.currency_id,
              source_id: 0,
              tol_qte: this.$store.state.options[0].tol_qte_cmd_plus
                ? this.$store.state.options[0].tol_qte_cmd_plus
                : 0,
              tol_rcpt: 25,
              mont_ht: this.pfm.mont_ht,
              mont_tva: this.pfm.mont_tva,
              mont_ttc: this.pfm.mont_ttc,

              port_id: this.pfm.port_id,
              incoterme: this.pfm.incoterme,
              mont_transport: parseInt(this.pfm.mont_transport),
              modetransport: this.pfm.modetransport,

              delai: parseInt(this.pfm.delai),
              validite: parseInt(this.pfm.validite),
              modalite_id: this.pfm.modalite_id,
              modepaiement: this.pfm.modepaiement,
              garantie: this.pfm.garantie,

              create_uid: parseInt(this.$store.state.me.id),
              write_uid: parseInt(this.$store.state.me.id),
            },
          };
          let r = await this.maj(CREATE_CMD, v);
          if (r) {
            let cmd = r.createCmd;
            // créer detail commande
            let l = [];
            for (let index = 0; index < this.detail_list.length; index++) {
              const element = this.detail_list[index];
              l.push({
                pfm_det_id: element.id,
                pu: element.pu,
                dem_det_id: element.dem_det_id,
                qte: parseFloat(element.qte),
                cmd_id: r.createCmd.id,
                delai: element.delai,
                adress_id: element.adress_id,
                produit_id: element.produit_id,
                article_id: element.article_id,
                fk_unit: element.fk_unit,
                gamme_id: element.gamme_id,
                tva_tx: parseFloat(element.tva_tx),
                create_uid: this.$store.state.me.id,
                write_uid: this.$store.state.me.id,
              });
            }
            let new_comments = [];
            if (this.comments.length > 0) {
              this.comments.forEach((element) => {
                new_comments.push({
                  document_id: parseInt(cmd.id),
                  document_type: 2,
                  comment_type: parseInt(element.comment_type),
                  comment: element.comment,
                  created_at: element.created_at,
                  create_uid: element.create_uid,
                });
              });
              new_comments.push({
                document_id: parseInt(cmd.id),
                document_type: 2,
                comment_type: 4,
                comment:
                  "------Fin Notes Proforma N° " +
                  this.pfm.nopfm +
                  "------------",
                created_at: new Date().toISOString().slice(0, 19),
                create_uid: this.$store.state.me.id,
              });
            }
            v = {
              input: l,
              cmd_id: r.createCmd.id,
              comments: new_comments.length > 0 ? new_comments : null,
            };
            let r1 = await this.maj(CREATE_CMD_DETAIL, v);
            if (r1) {
              let ok = await this.update_statut(4);
              if (ok) this.editedItem.statut_id = 4;

              //actualiser les alertes
            }
            this.$store.dispatch("Changed", true);
            this.$store.dispatch("Editing", false);
            if (this.tiertype == 1)
              this.$router.push({
                name: "commandes_c",
                params: { cmd: cmd },
              });
            else
              this.$router.push({
                name: "commandes_f",
                params: { cmd: cmd },
              });
          }
        }
      this.save_disable = false;
    },
    async select(query, v, refresh) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          this.progress = false;
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async requette(query, v) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async unlock() {
      this.is_motif = true;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir déverrouiller cette proforma?"
        )
      ) {
        let ok = await this.update_statut(1, this.editedItem.motif);
        if (ok) this.editedItem.statut_id = 1;
      }
      this.is_motif = false;
      this.$store.dispatch("Editing", false);
    },
    async valider_pfm() {
      if (await this.verif_modif()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir valider cette proforma?"
          )
        ) {
          let ok = await this.update_statut(2);
          if (ok) this.editedItem.statut_id = 2;
          this.$store.dispatch("Editing", false);
        } else this.update_modif();
      }
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.editedItem.id > 0) {
        let v = {
          input: {
            id: this.editedItem.id,
            modif_by: null,
            modif_date: null,
          },
        };

        await this.maj(UPDATE_PFM, v);
      }
    },
    closeDetailForm() {
      this.update_modif();
      this.mc++;
      this.isDetailClosed = true;
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    async OpenProductForm(item) {
      if (item.col == "code") {
        if (this.tiertype == 1)
          await this.$router
            .push({
              name: "product_tosell",
              params: { pfm: this.pfm, item: item, name: "proformas_c" },
            })
            .catch(() => {});

        if (this.tiertype == 2)
          await this.$router
            .push({
              name: "product_tobuy",
              params: { pfm: this.pfm, item: item, name: "proformas_f" },
            })
            .catch(() => {});
      } else if (
        item.produit_id ||
        item.id < 0 ||
        this.editedItem.statut_id == "1"
      ) {
        if (await this.verif_modif()) {
          this.product = item;
          this.product.pfm_id = this.editedItem.id;
          this.product.tier_id = this.editedItem.tier_id;
          this.dkey++;
          this.isDetailClosed = false;
        }
      } else {
        if (this.tiertype == 1)
          await this.$router
            .push({
              name: "product_tosell",
              params: { pfm: this.pfm, item: item, name: "proformas_c" },
            })
            .catch(() => {});
        if (this.tiertype == 2)
          await this.$router
            .push({
              name: "product_tobuy",
              params: { pfm: this.pfm, item: item, name: "proformas_f" },
            })
            .catch(() => {});
      }
    },

    async deletePfm() {
      if (this.editedItem.id < 0) {
        this.editedItem.id = null;
        this.$emit("cancel", this.editedItem);
      } else if (await this.verif_modif()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer cette Proforma?"
          )
        ) {
          this.save_disable = true;
          this.progress = true;
          this.$apollo
            .mutate({
              mutation: DELETE_PFM,
              variables: {
                id: this.editedItem.id,
              },
            })
            .then(() => {
              this.editedItem.id = null;
              this.$emit("change", this.editedItem);

              this.$store.dispatch("Changed", true);
            })
            .catch((error) => {
              this.progress = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        } else this.update_modif();
      }
      this.save_disable = false;
      this.$store.dispatch("Editing", false);
    },
    decimalDigits: function (value, n = 2) {
      return typeof value == "number" ? value.toFixed(n) : 0;
    },

    printPfm() {
      let doctype = [];
      doctype.push({
        lib: {
          content: "Facture Proforma",
          styles: { fontStyle: "bold", fontSize: 20, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      doctype.push({
        lib: {
          content: "N° :",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: this.editedItem.nopfm,
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Date : ",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: datefr(this.editedItem.date_pfm),
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Réference:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.pfm_ref ? this.editedItem.pfm_ref : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Mode de Réglement:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.mode ? this.mode : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Modalité de Paiement:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.modalite ? this.modalite : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Validité de l'Offre: ",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.validite
            ? this.editedItem.validite + " Jours"
            : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      if (this.editedItem.delai)
        doctype.push({
          lib: {
            content: "Délai de Livraison:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.delai
              ? this.editedItem.delai + " Jours"
              : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
      if (this.transport)
        doctype.push({
          lib: {
            content: "Mode de Transport:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.transport ? this.transport : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
      if (this.port)
        doctype.push({
          lib: {
            content: "Lieu d'embarquement:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.port ? this.port : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
      if (this.editedItem.incoterme)
        doctype.push({
          lib: {
            content: "Condition:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.incoterme ? this.editedItem.incoterme : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
      doctype.push({
        lib: {
          content: "Contact:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.contact ? this.editedItem.contact : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Tél. :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.contact_tel
            ? this.editedItem.contact_tel
            : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });

      let tier = [];
      tier.push({
        lib: {
          content: this.tiertype == 2 ? "Fournisseur :" : "Client :",
          styles: { fontStyle: "normal", fontSize: 12, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content: this.editedItem.tier_name ? this.editedItem.tier_name : "",
          styles: { fontStyle: "bold", fontSize: 12, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content: this.editedItem.tier
            ? this.editedItem.tier.adresse
              ? this.editedItem.tier.adresse
              : ""
            : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });

      tier.push({
        lib: {
          content: "Tél :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.tier
            ? this.editedItem.tier.tel
              ? this.editedItem.tier.tel
              : ""
            : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      tier.push({
        lib: {
          content: "Email :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.tier
            ? this.editedItem.tier.email
              ? this.editedItem.tier.email
              : ""
            : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });

      let foot = [];
      foot.push({
        libelle: this.editedItem.currency_id == 114 ? "Montant HT" : "Montant",
        value: this.numberWithSpace(this.subTotal.toFixed(2)),
      });
      this.taxTotal.forEach((element) => {
        foot.push({
          libelle: "TVA " + element.taux + " %",
          value: this.numberWithSpace(element.tva.toFixed(2)),
        });
      });

      if (this.taxTotal.length > 1) {
        foot.push({
          libelle: "Total TVA",
          value: this.numberWithSpace(this.sumtaxTotal("tva").toFixed(2)),
        });
      }
      if (
        this.subTotal > 0 &&
        this.sumtaxTotal("tva") == 0 &&
        this.editedItem.currency_id == 114
      ) {
        foot.push({
          libelle: "TVA ",
          value: "EXONORE",
        });
      }

      if (this.editedItem.mont_transport > 0) {
        foot.push({
          libelle: "Frêt",
          value: this.numberWithSpace(
            this.editedItem.mont_transport.toFixed(2)
          ),
        });
      }
      if (this.timbre > 0) {
        foot.push({
          libelle: "Droit de timbre (1%)",
          value: this.numberWithSpace(this.timbre.toFixed(2)),
        });
      }
      if (this.mont_exe_taxs > 0) {
        foot.push({
          libelle: "Exonoration de la TVA",
          value: {
            content: "-" + this.numberWithSpace(this.mont_exe_taxs.toFixed(2)),
            styles: { fontStyle: "normal" },
          },
        });
      }

      foot.push({
        libelle:
          this.editedItem.currency_id == 114
            ? "Montant TTC"
            : "Montant Total (" +
              (this.editedItem.devise ? this.editedItem.devise : "") +
              ")",
        value: {
          content: this.numberWithSpace(this.grandTotal.toFixed(2)),
          styles: { fontStyle: "bold" },
        },
      });
      let detailheaders = [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Ref.",
          value: "code",
          selected: true,
          align: "left",
          width: 20,
        },
        {
          text: "Désignation",
          value: "label",
          selected: true,
          align: "left",
          width: "auto",
        },
        {
          text: "Quantité",
          value: "qte",
          slot: "qte",
          width: 30,
          align: "end",
          selected: true,
        },
        {
          text: "Unité",
          value: "unit",
          selected: true,
        },
        {
          text: "PU HT",
          value: "pu",
          width: 25,
          align: "end",
          selected: true,
        },
        {
          text: "Montant HT",
          value: "ht",
          slot: "cur",
          width: 30,
          align: "end",
          selected: true,
        },
      ];
      let lettre = [];
      lettre.push({
        libelle: {
          content: "Arrêtée la présente facture proforma à la somme de :",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
      });
      lettre.push({
        libelle: {
          content: this.$lettre.NumberToLetter(this.grandTotal),
          styles: { halign: "left", fontStyle: "normal", fontSize: 10 },
        },
      });
      doc_template(
        this.$store,
        detailheaders,
        this.detail_list,
        doctype,
        tier,
        foot,
        lettre,
        this.editedItem.notes,
        "Facture Proforma"
      );
    },

    adjustTextAreaHeight: function (event) {
      var el = event.target;
      el.style.height = "1px";
      el.style.height = 25 + el.scrollHeight + "px";
    },
    sumtaxTotal(key) {
      // sum data in give key (property)
      return this.taxTotal
        ? this.taxTotal.reduce((a, b) => a + (b[key] || 0), 0)
        : 0;
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },

    async save() {
      if (this.$refs.form.validate()) {
        let ok = true;
        if (this.editedItem.tier)
          if (this.editedItem.tier.statut_id == 4) {
            ok = false;
            this.message = "erreur tier bloqué! ";
            this.type_alert = "error";
            this.alert = true;
            console.log("erreur tier bloqué!");
            ok = false;
          }
        if (ok) {
          this.save_disable = true;
          if (this.editedItem.id > 0) {
            let v = {
              input: {
                id: this.editedItem.id,
                tier_id: this.editedItem.tier_id,
                date_pfm: this.editedItem.date_pfm,
                comment: this.editedItem.comment,
                notes: this.editedItem.notes,
                statut_id: this.editedItem.statut_id,
                pfm_ref: this.editedItem.pfm_ref,
                cons_id: this.editedItem.cons_id,
                garantie: this.editedItem.garantie,

                port_id: this.editedItem.port_id,
                incoterme: this.editedItem.incoterme,
                mont_transport: parseInt(this.editedItem.mont_transport),

                modetransport: this.editedItem.modetransport,

                delai: this.editedItem.delai,
                validite: parseInt(this.editedItem.validite),
                modalite_id: this.editedItem.modalite_id,
                modepaiement: this.editedItem.modepaiement,
                tier_name: this.editedItem.tier_id
                  ? null
                  : this.editedItem.tier_name,

                write_uid: this.$store.state.me.id,
              },
            };
            let r = this.maj(UPDATE_PFM, v, true);
            if (r) {
              this.$emit("change", this.editedItem);
              this.$store.dispatch("Editing", false);
            }
          } else {
            this.editedItem.date_pfm = this.$store.state.today;
            let v = {
              input: {
                pfm_type: this.tiertype,
                tier_id: this.editedItem.tier_id,
                date_pfm: this.editedItem.date_pfm,
                statut_id: this.editedItem.statut_id,
                date_opr: this.editedItem.date_opr,
                comment: this.editedItem.comment,
                notes: this.editedItem.notes,
                pfm_ref: this.editedItem.pfm_ref,
                cons_id: this.editedItem.cons_id,

                port_id: this.editedItem.port_id,
                incoterme: this.editedItem.incoterme,
                mont_transport: parseInt(this.editedItem.mont_transport),
                modetransport: this.editedItem.modetransport,

                delai: this.editedItem.delai,
                validite: parseInt(this.editedItem.validite),
                modalite_id: this.editedItem.modalite_id,
                modepaiement: this.editedItem.modepaiement,
                garantie: this.editedItem.garantie,

                tier_name: this.editedItem.tier_id
                  ? null
                  : this.editedItem.tier_name,

                create_uid: parseInt(this.$store.state.me.id),
                write_uid: parseInt(this.$store.state.me.id),
              },
            };
            let r = await this.maj(CREATE_PFM, v);
            if (r) {
              this.editedItem.id = r.createPfm.id;
              this.$store.dispatch("Changed", true);
              this.$emit("change", this.editedItem);
            }
          }
          this.edit = false;
          this.$emit("edition", false);
          this.$store.dispatch("Editing", false);
          this.kdoc++;
        }
      }
      this.save_disable = false;
    },
  },
  computed: {
    variableadd() {
      let w = {
        TypeScope: this.tiertype == 1 ? 2 : 1,
        statut_id: [2, 3],
      };
      return w;
    },
    tierscursor() {
      return TIERS_CURSOR;
    },
    tiers_list() {
      let l = [];
      if (this.editedItem.cons_id) {
        let i = this.allconsults.findIndex(
          (elm) => elm.id == this.editedItem.cons_id
        );
        if (i >= 0) {
          let o = this.allconsults[i].alloffres.map((elm2) => elm2.tier_name);
          l = this.allconsults[i].tiers.filter(
            (elm) =>
              !o.includes(elm.tier_name) ||
              elm.tier_name == this.editedItem.tier_name
          );
        }
      }

      return l;
    },

    auth() {
      let a = this.tiertype == 1 ? "01022" : "01020";
      return a;
    },
    port() {
      let p;
      let i = this.ports.findIndex((elm) => elm.id == this.editedItem.port_id);
      if (i >= 0) p = this.ports[i].libelle;
      return p;
    },
    ports_etr() {
      let l = [];
      l = this.ports.filter((elm) => elm.fk_pays != "13");
      return l;
    },
    transport() {
      let m;
      let i = this.modestransports.findIndex(
        (elm) => elm.id == this.editedItem.modetransport
      );
      if (i >= 0) m = this.modestransports[i].mode;
      return m;
    },
    modes_list() {
      return this.modes.filter((elm) =>
        this.editedItem.currency_id
          ? elm.type == (this.editedItem.currency_id == 114 ? 0 : 1)
          : true
      );
    },
    formTitle() {
      let t = this.tiertype == 1 ? "Proforma" : "Offre";
      return this.editedItem.id < 0 || !this.editedItem.nopfm
        ? t
        : t + " N° " + this.editedItem.nopfm;
    },
    garantie() {
      return grt ? true : false;
    },
    Qdelete() {
      return DELETE_PRODUIT;
    },
    logo() {
      return myLogo;
    },
    myname() {
      return myName;
    },
    myrc() {
      return myRC;
    },
    myif() {
      return myIF;
    },
    myart() {
      return myART;
    },
    mode() {
      let i = this.modes.findIndex(
        (elm) => elm.id == this.editedItem.modepaiement
      );
      let modepaiement = "";
      if (i >= 0) modepaiement = this.modes[i].mode;
      return modepaiement;
    },
    modalite() {
      let i = this.modalites.findIndex(
        (elm) => elm.id == this.editedItem.modalite_id
      );
      let modalite = "";
      if (i >= 0) modalite = this.modalites[i].libelle;
      return modalite;
    },

    comments: function () {
      return this.editedItem.comments ? this.editedItem.comments : [];
    },
    operations: function () {
      return this.editedItem.operations ? this.editedItem.operations : [];
    },

    detail_list: function () {
      return this.editedItem.pfmdetails ? this.editedItem.pfmdetails : [];
    },
    clt_details: function () {
      let list = [];
      if (this.editedItem.clt_details) list = this.editedItem.clt_details;
      return list;
    },
    subTotal: function () {
      var total = this.detail_list
        ? this.detail_list.reduce(function (accumulator, item) {
            return accumulator + item.pu * item.qte;
          }, 0)
        : 0;
      return total;
    },

    taxTotal: function () {
      let taxs = [];
      if (
        this.detail_list &&
        (this.editedItem.exe_tva != "1" || this.exe_tva) &&
        (this.editedItem.currency_id == 114 || !this.editedItem.currency_id)
      ) {
        this.detail_list.forEach((element) => {
          if (element.tva_tx) {
            let i = taxs.findIndex((elm) => elm.taux == element.tva_tx);
            if (i >= 0)
              taxs[i].tva =
                taxs[i].tva + (element.pu * element.qte * element.tva_tx) / 100;
            else
              taxs.push({
                taux: element.tva_tx,
                tva: (element.pu * element.qte * element.tva_tx) / 100,
              });
          }
        });
      }
      return taxs;
    },
    timbre: function () {
      var timbre = 0;
      if (this.editedItem.modepaiement == "4") {
        let taxs = 0;
        this.taxTotal.forEach((element) => {
          taxs = taxs + element.tva;
        });

        timbre = Math.ceil((this.subTotal + taxs) / 100);
        if (timbre < 5) timbre = 5;
        if (timbre > 2500) timbre = 2500;
      }
      return timbre;
    },
    ttc: function () {
      let taxs = 0;
      this.taxTotal.forEach((element) => {
        taxs = taxs + element.tva;
      });

      var total =
        this.subTotal + taxs + this.timbre + this.editedItem.mont_transport;
      return total;
    },
    mont_exe_taxs: function () {
      let taxs = 0;
      if (
        this.detail_list &&
        this.exe_tva &&
        this.editedItem.currency_id == 114
      ) {
        this.detail_list.forEach((element) => {
          if (element.tva_tx) {
            taxs = taxs + (element.pu * element.qte * element.tva_tx) / 100;
          }
        });
      }
      return taxs;
    },
    grandTotal: function () {
      var total = this.ttc - this.mont_exe_taxs;
      return total;
    },
  },
};
</script>
<style>
.scroll {
  width: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
